<template>
  <Layout>
    <div class="main-rank">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' />
        <!-- 广告图 -->
        <Banner class="img" :list="bannerList" height="4.92rem" v-ani.fade-up />
        <!-- 分类 -->
        <CateCarousel @change="cateChange"></CateCarousel>
        <!-- 列表 -->
        <div class="md-rank-2">
          <div class="list">
            <div class="li c-card flex-middle" v-for="(item, index) in listArr" :key="index" v-ani.fade-up>
              <div class="num" v-if="index < 4">
                <div class="s18 num-text color-f text-center">{{ index + 1 }}</div>
              </div>
              <img :src="item.logo" class="avatar">
              <div class="rg">
                <div class="row-1 flex-middle">
                  <div class="name s22 color-2">{{ item.company_name }}</div>
                  <div class="c-user-type">
                    <img src="@/assets/images/icon-medal.png">
                    <span class="s12">供应商</span>
                  </div>
                </div>
                <div class="row-2 s16 color-4 flex-middle">
                  <UserRank :hatArr="item.level?.hat" :starsArr="item.level?.stars"></UserRank>
                  <div class="jifen">易筑分：{{ item.level?.score }}分</div>
                  <div class="lei">主营类目：{{ item.name }}</div>
                </div>
              </div>
              <BaseButton tag='router-link' size='large' style-type='light'
                :to="`/shop/seller?suppliers_id=${item.suppliers_id}`">进店看看</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { IndexBillboardApi, IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    let type = this.$route.query.type;
    if (type == 1) {
      this.routes[1].name = '优质榜';
    } else {
      this.routes[1].name = '热度榜';
    }
    this.listFn();
    // 轮播
    IndexBannerApi({ from: 429 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      bannerList: [],
      routes: [
        { name: '首页', link: '/' },
        { name: '优质榜', link: '' }
      ],
      listArr: [],
      cateArr: [],
      cat_id: "",
    };
  },
  methods: {
    listFn() {
      IndexBillboardApi({ type: this.$route.query.type, cat_id: this.cat_id }).then(({ status, data }) => {
        if (status == 200) {
          this.listArr = data;
        }
      })
    },
    cateChange(e) {
      this.cat_id = e;
      this.listFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-rank {
  padding-bottom: .42rem;

  ::v-deep .banner .text {
    left: 0;
    right: 2.13rem;
    text-align: right;
  }
}

.md-rank-2 {
  margin-top: 0.2rem;

  .list {
    .li {
      margin-bottom: 0.2rem;
      padding: .42rem .62rem .4rem .5rem;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        .num {
          background-image: url(~@/assets/images/icon-rank-1.png);
        }
      }

      &:nth-child(2) {
        .num {
          background-image: url(~@/assets/images/icon-rank-2.png);
        }
      }

      &:nth-child(3) {
        .num {
          background-image: url(~@/assets/images/icon-rank-3.png);
        }
      }
    }

    .num {
      position: absolute;
      left: .11rem;
      top: 0.11rem;
      width: .3rem;
      height: .39rem;
      background-size: 100%;
      background-position: center top;

      .num-text {
        width: .24rem;
        line-height: .24rem;
        margin: .03rem auto 0;
      }
    }

    .avatar {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: .2rem;
    }

    .rg {
      margin-right: auto;



      .row-2 {
        margin-top: 0.16rem;

        .c-level-wrap {
          margin-right: .13rem;
        }

        .jifen {
          margin-right: .48rem;
        }
      }
    }

    .base-button {
      min-width: 1.5rem;
    }
  }
}
</style>